export const loadScripts = async () => {
    // Highcharts Block
    if (document.querySelector(".highcharts-block-js")) {
        await import("../../../../../us/js/src/highcharts-block.js");
    }

    // Performance Charts (highcharts)
    if (document.querySelector(".performance-chart-block-js")) {
        await import("../../../../../eu/js/src/sources/performance-charts.js");
    } 

    //education scripts for carousel
    if (document.querySelector('.fund-carousel-block__main--desktop:not(.fund-carousel-block__main-au-campaign)')) {
        await import("../override/education.js");
    } 

    //campaign pages scripts for carousel
    if (document.querySelector('.fund-carousel-block__main--desktop.fund-carousel-block__main-au-campaign')) {
        await import("../override/campaign-page.js");
    } 

    // Carousels
    if (document.querySelector('.slider-for') ||
        document.querySelector('.recommended-insights__list')||
        document.querySelector('.carousel-block__main--desktop') ||
        document.querySelector('.fund-carousel-block__main--desktop:not(.fund-carousel-block__main-au-campaign)') ||
        document.querySelector('.carousel-wrapper__main--desktop') ||
        document.querySelector('.more-from-category__main--desktop') ||
        document.querySelector('.latest-digital-grid__main--desktop') || 
        document.querySelector('ve-relatedmediablock') ||
        document.querySelector('[data-template*="Carousel"]')
    ) {
        await import("./carousels.js");
    };
};

export const loadScrollDelayedScripts = async () => {
    //homepage carousel
    if (document.querySelector(".promo-banner-hp")) {
        await import("../override/home-page-carousel.js");
    }
};