//make jQuery accessible globally.
window.jQuery = require('jquery');
window.$ = jQuery;

window.Popper = require('popper.js');
Popper.default.Defaults.modifiers.computeStyle.gpuAcceleration = false
require('bootstrap');
const axios = require('axios');
const debounce  = require('lodash/debounce');
const waypoints = require('./waypoints.js');
const { isInsideElement } = require('../lib/utils/dom-utils.js');
const { scrollToTop } = require('../lib/utils/scroll-utils.js');
const { isInterentExplorerDevice } = require('../lib/utils/window-utils.js');

import { loadScripts, loadScrollDelayedScripts } from './index-module.js';

window.addEventListener("DOMContentLoaded", () => {
    (async () => {
        await loadScripts();
    })();

    //Scroll listener to only load delayed scripts below the fold
    const loadScriptsScrollListener = debounce(() => {
        const heightOffset = window.innerHeight / 8;
        const ScrolledTop = window.pageYOffset || document.documentElement.scrollTop
        if (ScrolledTop > heightOffset) {
            (async () => {
                await loadScrollDelayedScripts();
            })();
            return window.removeEventListener("scroll", loadScriptsScrollListener);
        }
    }, 100);
    window.addEventListener("scroll", loadScriptsScrollListener);
    loadScriptsScrollListener(); //trigger it once anyway, incase the page is already scrolled down.
})


/* eslint-disable no-undef */
$(function() {

  $('.recommended-insights .tab-nav__link').on('shown.bs.tab', function() {
    const id = $(this).index('.recommended-insights .tab-nav__link');
    $('.tab-nav__list li').each(function(index) {
      $(`.recommended-insights__actions-${index}`).css('display', 'none');
    });
    $(`.recommended-insights__actions-${id}`).css('display', 'block');
  });
  /* eslint-enable max-len */
  // SiteHeader
  $(document).on('click.bs.dropdown.data-api', '.site-header__mobile-submenu', (event) => {
    if (!isInsideElement(event, 'a') && !isInsideElement(event, 'button')) {
      event.stopPropagation();
    }
  });

  $(document).on('click.bs.dropdown.data-api', '.site-header__mobile-submenu-toggle', () => {
    scrollToTop();
  });

  $(document).on('click.bs.dropdown.data-api', '.site-header__submenu .list-group-item-action', (event) => {
    event.stopPropagation();
  });

  $(document).on('click.bs.dropdown.data-api', '.site-header__submenu', (event) => {
    if (!isInsideElement(event, 'a') && !isInsideElement(event, 'button')) {
      event.stopPropagation();
    }
  });

  $('.site-header__submenu-action').on('mouseenter', (event) => {
    const actionElement = $(event.target),
          menuId = actionElement.attr('data-menu'),
          descriptionId = actionElement.attr('data-description'),
          currentActiveAction = $('#' + menuId + ' .list-group .list-group-item.active'),
          currentActivePane = $('#' + menuId + ' .site-header__submenu-content .tab-pane.active'),
          targetPane = $('#' + menuId + ' .site-header__submenu-content .tab-pane#' + descriptionId);

    $('#' + menuId + ' .site-header__submenu-content').attr('data-pane', currentActiveAction.attr('data-default'));
    currentActivePane.removeClass('show active');
    targetPane.addClass('show active');
  });

  $('.site-header__submenu-action').on('mouseleave', (event) => {
    const actionElement = $(event.target),
          menuId = actionElement.attr('data-menu'),
          descriptionId = actionElement.attr('data-description'),
          currentActiveAction = $('#' + menuId + ' .list-group .list-group-item.active'),
          currentActiveActionTarget = currentActiveAction.attr('data-default'),
          currentActivePane = $('#' + menuId + ' .site-header__submenu-content .tab-pane#' + descriptionId),
          newActivePane = $('#' + menuId + ' .site-header__submenu-content .tab-pane#' + currentActiveActionTarget);

    currentActivePane.removeClass('show active');
    newActivePane.addClass('show active');
  });

  $('.site-header .navbar-toggler').on('click', (event) => {
    const toggleElement = $(event.target),
          targetId = toggleElement.attr('data-target'),
          targetElement = $(targetId);

    toggleElement.toggleClass('collapsed');
    targetElement.toggleClass('show');
  });

  $('.site-header__mobile-submenu-toggle').on('click', (event) => {
    const toggleElement = $(event.target),
          targetId = toggleElement.attr('data-target'),
          targetElement = $(targetId);

    targetElement.toggleClass('show');
  });


  $('.site-header__mobile-sub-submenu-toggle').on('click', (event) => {
    const toggleElement = $(event.target),
          targetId = toggleElement.attr('data-target'),
          targetElement = $(targetId);

    targetElement.toggleClass('show');
  });

/*****
 *  THESE ARE MOVED UNDER THE FUND SCRIPTS SINCE THEY ARE ONLY USED IN THE FUND EXPLORER.
 * 
 * 

  const $table = $('.table');
  $table.floatThead({
    top: 84,
    zIndex: 1,
    responsiveContainer: function($table) {
      return $table.closest('.tab-content');
    },
    getSizingRow: function($table) {
      return $table.find('tbody tr:not(.grouping):visible:first>*');
    },
    useAbsolutePositioning: true,
  });

  // Table header leg for IE
  $('.fund-explorer-table').each(function() {
    if (isInterentExplorerDevice) {
      $('body').on('mousewheel', function() {
        event.preventDefault();
        const wheelDelta = event.wheelDelta;
        const currentScrollPosition = window.pageYOffset;
        window.scrollTo(0, currentScrollPosition - wheelDelta);
      });
    }
    document.getElementsByTagName('body')[0].addEventListener('wheel', function(event) {
      event.stopPropagation();
    }, true);
  });

  // Prices table - view and hide data
  $('.fund-explorer-table__data-link').on('click', function() {
    const cell = $(this),
          parent = cell.closest('.fund-explorer-table__expandable-row');
    if (cell.hasClass('collapsed')) {
      parent.addClass('selected-row');
    }
    else {
      parent.removeClass('selected-row');
    }
  });


    **/

  waypoints.init();
  waypoints.sectionHeaderInit();
  waypoints.tableHeaderInit();
});

/* scroll to top */
$(window).scroll(function() {
  if ($(window).scrollTop() >= $('main').offset().top + $('main').outerHeight() - window.innerHeight) {
    $('.scroll-to-top').css({ 'animation': 'fadeIn 0s', 'visibility': 'visible' });
    $('.scroll-to-top').addClass('table-sticky').removeClass('sticky-fixed');
  }
  else if ($(this).scrollTop() < 100) {
    $('.scroll-to-top').css({ 'animation': 'fadeIn 0s', 'visibility': 'hidden' });
  }
  else {
    $('.scroll-to-top').css({ 'animation': 'fadeOut 0s', 'visibility': 'visible' });
    $('.scroll-to-top').addClass('sticky-fixed').removeClass('table-sticky');
  }
});

$('.scroll-to-top').on('click', function() {
  $('.scroll-to-top').css({ 'animation': 'fadeIn 0s', 'visibility': 'hidden' });
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

/* sticky nav shadow */
$('.site-header__nav-item').hover(function() {
  $('.site-header.sticky').css('z-index', 1060);
}, function() {
  $('.site-header.sticky').css('z-index', 1020);
});

/*// Print page - moved to insights-components.js
$('.share-widget .print').on('click', function () {
    $('.share-widget').removeClass('show');
    $('.share-widget__menu').removeClass('show');
    window.print();
});*/

// Trap focus inside modal and menus
const focusableEl = 'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

$('.focusable-menu').each(function () {
    const selectFocusElement = $(this);

    const firstFocusableEl = selectFocusElement[0].querySelectorAll(focusableEl)[0];
    const focusableCn = selectFocusElement[0].querySelectorAll(focusableEl);
    const lastFocusableEl = focusableCn[focusableCn.length - 1];

    document.addEventListener('keydown', function (e) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
        if (!isTabPressed) {
            return;
        }
        if (e.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                lastFocusableEl.focus();
                e.preventDefault();
            }
        }
        else {
            if (document.activeElement === lastFocusableEl) {
                firstFocusableEl.focus();
                e.preventDefault();
            }
        }
    });
    if (firstFocusableEl != undefined && typeof firstFocusableEl.focus == "function") {
        firstFocusableEl.focus();
    }
    
});

// Alert bar
$('.alert-bar__ctalink').on('click', function () {
    $('.alert-bar__content').hide();
});

// Tiny url
//$(function () {
//    //const webIntentEl = $('.callout-text__icon-link, #twitter');
//    const webIntentEl = $('#twitter');
//    if (webIntentEl) {
//        webIntentEl.each(function () {
//            const intent = $(this);

//            const url = new URL(intent.attr('href'));

//            (function () {
//                axios({
//                    method: 'get',
//                    url: `https://tinyurl.com/api-create.php?url=${window?.location?.href}`,
//                })
//                    .then(function (response) {
//                        if (response.status === 200) {
//                            url.searchParams.set('url', response.data);
//                            intent.attr('href', url.href);
//                        }
//                    })
//                    .catch(function (error) {
//                        // eslint-disable-next-line no-console
//                        console.error(error);
//                    });
//            })();
//        });
//    }
//});

// Linkedin Url
//$(function () {
//    const webIntentEl = $('.share-widget__menu-inner #linkedin');

//    if (webIntentEl) {
//        webIntentEl.each(function () {
//            const intent = $(this);
//            const url = new URL(intent.attr('href'));
//            url.searchParams.set('url', window?.location?.href);
//            intent.attr('href', url.href);
//        });
//    }
//});

// Follow and Share Widget menu position
function followAndShareWidgetPosition() {
    const widgetDropdownEL = $('.widget.dropdown');

    if (widgetDropdownEL) {
        widgetDropdownEL.each(function (i) {
            if (widgetDropdownEL[i].classList.contains("ignore-placement-fix")) {
                return;
            }

            const widgetEl = $(this);

            const widgetOffsetLeft = widgetEl.offset().left;
            const widgetOffsetRight = $('body').innerWidth() - widgetEl.width() - widgetEl.offset().left;
            const widgetMenuWidth = 300;
            const offset = 5;
            const widgetCaretPosition = widgetMenuWidth / 2 - offset;

            const widgetMenuEl = widgetEl.find('.widget-menu');

            if (widgetOffsetLeft > widgetCaretPosition && widgetOffsetRight > widgetCaretPosition) {
                widgetMenuEl.removeClass('widget-menu-left widget-menu-center widget-menu-right');
                widgetMenuEl.addClass('widget-menu-center');
            }
            else if (
                widgetOffsetLeft > widgetCaretPosition &&
                widgetOffsetRight < widgetCaretPosition
            ) {
                widgetMenuEl.removeClass('widget-menu-left widget-menu-center widget-menu-right');
                widgetMenuEl.addClass('widget-menu-left');
            }
            else if (
                widgetOffsetLeft < widgetCaretPosition &&
                widgetOffsetRight > widgetCaretPosition
            ) {
                widgetMenuEl.removeClass('widget-menu-left widget-menu-center widget-menu-right');
                widgetMenuEl.addClass('widget-menu-right');
            }
        });
    }
}

followAndShareWidgetPosition();
$(window).on('resize', debounce(followAndShareWidgetPosition, 250));

function AttachmentBlock() {
    // Attachment Block
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const shimmer = entry.target.querySelector('.attachment-block__content-bg-shimmer');

            if (entry.isIntersecting && shimmer) {
                shimmer.classList.add('gleam-animation');
                return;
            }
        });
    });

    document.querySelectorAll('.attachment-block').forEach((i) => {
        if (i) {
            observer.observe(i);
        }
    });

}

if (typeof IntersectionObserver != "function") {
    /* Microsoft Internet Explorer detected in. || intersection observer not present */
    //First get the IO Polyfill
    $.getScript("https://polyfill.io/v3/polyfill.min.js?features=IntersectionObserver").done(() => {
        //then run yall.
        AttachmentBlock();
    });
} else {
    AttachmentBlock();
}

// Utility menu and Register form - country and state logic
$(function () {
    $('.form-country-container').each(function () {
        const countryField = $(this);
        countryField.find('.dropdown-menu .dropdown-item').on('click', function () {
            const dropdownMenuItemLabel = $(this).text();

            // Update selected option
            countryField.find('#country-dropdown .dropdown__label').text(dropdownMenuItemLabel);
            countryField
                .find('.dropdown__button')
                .find('option')
                .each(function () {
                    const option = $(this);
                    if (option.text() === dropdownMenuItemLabel) {
                        option.attr('selected', 'selected');
                    }
                    else {
                        option.removeAttr('selected');
                    }
                });

            // Disable state field if the country is not 'United States'
            const stateDropdown = countryField.next().find('#state-dropdown');
            if (dropdownMenuItemLabel !== 'United States') {
                stateDropdown.attr({
                    disabled: true,
                    'aria-disabled': true,
                });
            }
            else {
                stateDropdown.attr({
                    'disabled': false,
                    'aria-disabled': false,
                });
            }
        });
    });
});


$('#site-header-search').on('shown.bs.modal', function () {
    $('.modal-backdrop').appendTo('.root');
});

$('#gateway-modal').on('shown.bs.modal', function () {
    $('.modal-backdrop').css('z-index', 980);
});
// alertbar button hover
$('.alert-bar__ctalink').hover(function () {
    $('.alert-bar__left-label').toggleClass('text-decoration-none');
});

// header height
$('.alert-bar__ctalink').on('click', function () {
    $('.sticky-wrapper').css('height', '95');
});

/* tabel header sticky
$(window).scroll(function() {
  $('.fund-explorer-table').each(function() {
    if ($(window).scrollTop() >= $(this).offset().top + $(this).height() ||
      $(window).scrollTop() < $(this).offset().top) {
      $(this).find('.fund-explorer-table__filter').removeClass('sticky');
    }
    else {
      $(this).find('.fund-explorer-table__filter').addClass('sticky');
    }
  });
}); 

/* popover 
$(function() {
  $('.fund-explorer-table-tooltip-item').each(function(i, e) {
    const $ele = $(e),
          $popoverLink = $ele.next('.fund-explorer-table-tooltip-content'),
          popoverID = 'popoverid' + String(Math.random()).substr(2);
    $(this).attr('id', popoverID);
    $popoverLink.attr('id', ('po' + popoverID));
    $ele.popover({
      html: true,
      trigger: 'hover',
      content: function() {
        const id = $(this).attr('id');
        return $('#po' + id).html();
      },
      popperConfig: {
        placement: 'right-start',
      },
    });
  });
});

// On Thead Popover
$('.sort-thead').hover(function() {
  $('.floatThead-container').addClass('popover-height');
}, function() {
  $('.floatThead-container').removeClass('popover-height');
});

//daterangepicker
$(function() {
  const startDate = moment('03/15/2020'),
        endDate = moment('03/15/2021');
  $('.datepicker span').html(startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY'));
  $('.datepicker-btn').daterangepicker({ startDate: '', endDate: '' });

  function cb(ele) {
    $('.nav-item a').click(function() {
      const tabValue = $(this).text(),
            dateBtn = $(this).closest('ul').closest('div').closest('.pt-4').find('.datepicker-btn'),
            dateShow = $(this).closest('ul').closest('div').closest('.pt-4').find('.datepicker-date');
      dateShow.addClass('active-date active');
      if (tabValue === 'Last 1 Year') {
        dateBtn.data('daterangepicker').setStartDate('03/15/2020');
        dateBtn.data('daterangepicker').setEndDate('03/15/2021');
        dateShow.text('03/15/2020 - 03/15/2021');
      }
      else if (tabValue === 'Last 5 Year') {
        dateBtn.data('daterangepicker').setStartDate('03/15/2016');
        dateBtn.data('daterangepicker').setEndDate('03/15/2021');
        dateShow.text('03/15/2016 - 03/15/2021');
      }
      else if (tabValue === '2020 - Q4') {
        dateBtn.data('daterangepicker').setStartDate('10/01/2020');
        dateBtn.data('daterangepicker').setEndDate('12/31/2020');
        dateShow.text('10/01/2020 - 12/31/2020');
      }
      else if (tabValue === '2020 - Q3') {
        dateBtn.data('daterangepicker').setStartDate('07/01/2020');
        dateBtn.data('daterangepicker').setEndDate('09/30/2020');
        dateShow.text('07/01/2020 - 09/30/2020');
      }
      else if (tabValue === '2020 - Q2') {
        dateBtn.data('daterangepicker').setStartDate('04/01/2020');
        dateBtn.data('daterangepicker').setEndDate('06/30/2020');
        dateShow.text('04/01/2020 - 06/30/2020');
      }
      else if (tabValue === '2020 - Q1') {
        dateBtn.data('daterangepicker').setStartDate('01/01/2020');
        dateBtn.data('daterangepicker').setEndDate('03/31/2020');
        dateShow.text('01/01/2020 - 03/31/2020');
      }
      else {
        return;
      }
    });

    $('.datepicker-btn').on('apply.daterangepicker', function() {
      const tabSelect = $(this).closest('div').parent('div').prev('.fund-explorer-table__graph');
      $(this).prev('.datepicker-date').removeClass('active');
      if ($('.datepicker .datepicker-date').hasClass('active-date')) {
        tabSelect.find('.nav-link.active').removeClass('active');
      }
    });

    return function(startDate, endDate) {
      const datepickerSpan = $(ele).prev('span');
      datepickerSpan.html(startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY'));
      datepickerSpan.addClass('active-date');
      datepickerSpan.removeClass('active');
    };
  }

  $('[id^=date-]').each(function() {
    const idRange = $(this).attr('id');
    $(this).daterangepicker({
      parentEl: '#daterange-' + idRange,
      startDate: '03/15/2020',
      endDate: '03/15/2021',
      autoUpdateInput: false,
      linkedCalendars: false,
      showCustomRangeLabel: false,
      cancelClass: 'daterangepicker-cancelbtn',
      opens: 'center',
      drops: 'up',
      locale: {
        format: 'MM/DD/YYYY',
        applyLabel: 'Go',
        cancelLabel: '',
      },
    }, cb(this));
  });
  cb(this)(startDate, endDate);
});

$(document).ready(function() {
  $('.daterangepicker .ranges').after('<h5 class="daterangepicker-title">Select Date Range</h5>');
});

//Date selector popup - unbold
$('.fund-explorer-table__graph .nav-item a').click(function() {
  if ($(this).find('active')) {
    $('.datepicker .active-date').addClass('active');
  }
});

*/

/**
 Video player moved to overrides/video-player
 */


