//get the Gateway
/*require("gateway.js");*/

//for webinar notifications
//require("./generic-promo-banner.js");

//adding spin.js which might be a global dependancy.
import {Spinner} from 'spin.js';
window.Spinner = Spinner;

//spinner - based on spin.js -- does not seem to be working!
require("./spinner.js");

//This was in the original US bundle -- not sure what it does though.
require("./personalized-content.js");

// To remove widows.
require("./widont");

// Video Player.
require("./video-player");

// Miscellaneous code.
require("./lazy-loader");

// Other components.
require("./components");

// Other components.
require("./forms-and-subscriptions");

// AHP, Menu, Masthead and footer related code.
require("./menu-masthead-footer");

// To convert EPi dropdowns to custom dropdowns.
require("./custom-dropdown");

// To drive search functionality.
require("./search");

// Homepage scripts
require("./home-page");

//export jQuery for outside use.
window.$ = $;

//insights scripts
require("./insights-components");

//au header scripts
require("./global-nav-au");
require("./nav-au");
require("./mobile-responsive-au");

//lazy loading scripts
require("./lazy-scripts");

//expandable nav
require("./expandable-menu/expandable-nav");
require("./expandable-menu/share-menu");

//au webinar
require("./webinar-au");
