function educationScripts() {

    /**
     * Fund Carousel for Education Page
     */
    $('.fund-carousel-block__main--desktop:not(.fund-carousel-block__main-au-campaign)').each(function (id) {
        var slider = $(this);
        this.id = "fund-carousel-block-".concat(id);
        slider.on('init reInit afterChange', function (_, slick, currentSlide) {
            var currentSlideNumber = currentSlide ? currentSlide : 0;
            var i = currentSlideNumber + 1;
            var slidesToShow = slick.options.slidesToShow;
            var fundCarousel = $("#fund-carousel-block-".concat(id)).prev();
            var el = fundCarousel.find(".fund-carousel-block__main--desktop-count");
            var endSlide = Math.min(currentSlideNumber + slidesToShow, slick.slideCount); //count of the last slide being displayed i.e. 3 in 1-3 of 5
            if (slidesToShow > 1) {
                el.html("<b>".concat(i, "</b> - <b>").concat(endSlide, "</b> of <span>").concat(slick.slideCount, "</span>"));
            } else {
                el.html("<b>".concat(i, "</b> of <span>").concat(slick.slideCount, "</span>"));
            }
            fundCarousel.find("button.fund-carousel-block__control[aria-disabled='false']").attr("data-ve-gtm", "arrow-more");
            fundCarousel.find("button.fund-carousel-block__control[aria-disabled='true']").removeAttr("data-ve-gtm");
        });
        /* slider.slick({
             slidesToShow: 1,
             slidesToScroll: 1,
             infinite: false,
             arrows: true,
             appendArrows: $('.fund-carousel-block__control-container')[id],
             prevArrow: "\n        <button class=\"fund-carousel-block__control fund-carousel-block__control--prev\" type=\"button\">\n          <svg\n            aria-hidden=\"true\"\n            focusable=\"false\"\n            data-prefix=\"far\"\n            data-icon=\"chevron-left\"\n            class=\"svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon\"\n            role=\"img\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            viewBox=\"0 0 18 18\"\n          >\n            <path\n              fill=\"currentColor\"\n              d=\"M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z\"\n            >\n            </path>\n          </svg>\n        </button>\n      ",
             nextArrow: "\n        <button class=\"fund-carousel-block__control fund-carousel-block__control--next\" type=\"button\">\n          <svg\n            aria-hidden=\"true\"\n            focusable=\"false\"\n            data-prefix=\"far\"\n            data-icon=\"chevron-right\"\n            class=\"svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon\"\n            role=\"img\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            viewBox=\"0 0 18 18\"\n          >\n            <path\n              fill=\"currentColor\"\n              d=\"M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z\"\n            >\n            </path>\n          </svg>\n        </button>\n      ",
             mobileFirst: true,
             responsive: [{
                 breakpoint: 1199,
                 settings: {
                     slidesToShow: 3,
                     slidesToScroll: 1
                 }
             }, {
                 breakpoint: 767,
                 settings: {
                     slidesToShow: 2,
                     slidesToScroll: 1
                 }
             }]
         });*/
    });
}

educationScripts()

