$('.fund-carousel-block__main--desktop.fund-carousel-block__main-au-campaign').each(function (id) {
    const slider = $(this);
    const sliderContainer = $(this).parent(".fund-carousel-au-campaign");
    this.id = `fund-carousel-au-campaign-${id}`;

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: true,
        appendArrows: sliderContainer.find('.fund-carousel-block__control-container'),
        prevArrow: `
        <button class="fund-carousel-block__control fund-carousel-block__control--prev" type="button" aria-label="Scroll left">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M13.13 2.26a.75.75 0 00-.22-.54.75.75 0 00-1.07 0L5.1 8.47a.75.75 0 000 1.06l6.75 6.75a.75.75 0 001.07 0 .75.75 0 000-1.06L6.69 9l6.22-6.22a.75.75 0 00.21-.52z"
            >
            </path>
          </svg>
        </button>
      `,
        nextArrow: `
        <button class="fund-carousel-block__control fund-carousel-block__control--next" type="button" aria-label="Scroll right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-16 fa-lg icon"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
          >
            <path
              fill="currentColor"
              d="M4.87 15.74a.75.75 0 00.22.54.75.75 0 001.07 0l6.75-6.75a.75.75 0 000-1.06L6.16 1.72a.75.75 0 00-1.07 0 .75.75 0 000 1.06L11.31 9 5.1 15.22a.75.75 0 00-.22.52z"
            >
            </path>
          </svg>
        </button>
      `,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    slider.slick(settings);

    // To initialise slide numbers
    var initSlide = 1;
    var endSlide = settings.slidesToShow;
    var totalSlides = slider.find(".slick-track").children(".slick-slide:not(.slick-cloned)").length;
    if (window.innerWidth < 1199) { endSlide--; }
    if (totalSlides > endSlide) {
        window.$(".fund-carousel-block__main--desktop-count").removeClass("d-none");
        window.$(".fund-carousel-block__main--desktop-count").html("<b>".concat(initSlide, "</b> - <b>").concat(endSlide, "</b> of <span>").concat(totalSlides, "</span>"));
    }

    slider.on('init reInit afterChange', function (_, slick, currentSlide) {
        var currentSlideNumber = currentSlide ? currentSlide : 0;
        var i = currentSlideNumber + 1;
        var slidesToShow = slick.options.slidesToShow;
        var el = sliderContainer.find(".fund-carousel-block__main--desktop-count");
        var endSlide = Math.min(currentSlideNumber + slidesToShow, slick.slideCount); //count of the last slide being displayed i.e. 3 in 1-3 of 5

        // Display numbers only if there are more items than the slidesToShow number
        if (slick.slideCount <= slidesToShow) { el.addClass("d-none"); return };

        el.removeClass("d-none");

        // To display correct current slide when showing the last set of items
        var lastPossibleStartSlide = Math.floor(slick.slideCount / slidesToShow) * slidesToShow;
        if (i > lastPossibleStartSlide) i = lastPossibleStartSlide;

        if (slidesToShow > 1) {
            el.html("<b>".concat(i, "</b> - <b>").concat(endSlide, "</b> of <span>").concat(slick.slideCount, "</span>"));
        } else {
            el.html("<b>".concat(i, "</b> of <span>").concat(slick.slideCount, "</span>"));
        }
    });
});